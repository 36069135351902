html,body {
    height: 100%;
    background-color: #fff;
    overflow-y: overlay;
}
#root {
    height: 100%;
}
.cnt-body {
    font-size: 14px;
    height: 100%;
}
#page-content {
    flex: 1 0 auto;
}
.cnt-footer {
    flex-shrink: 0;
}

.cnt-color1    { color: #EADEB8; }
.cnt-color1-bg { background-color: #EADEB8; }
.cnt-color2    { color: #CFB784; }
.cnt-color2-bg { background-color: #CFB784; }
.cnt-color3    { color: #C56824; }
.cnt-color3-bg { background-color: #C56824; }
.cnt-color4    { color: #A09F57; }
.cnt-color4-bg { background-color: #A09F57; }

.cnt-menutop-rightmenu a {
    padding: 0 10px;
}

.cnt-card-deck {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: .5rem;
}
.cnt-card {
    width: 16rem;
}

.cnt-userchef-menutop a {
    padding: 0 10px;
}

.cnt-cal-dishes-list-item {
    color: #fff;
    background-color: #C56824;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0.3);
    border-radius: 4px;
    cursor: move;
}
.fc-h-event {
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0,0,0,0.3) !important;
    border-radius: 5px !important;
}
.cnt-cal-item {
    font-weight: bold;
    white-space: normal;
}
.cnt-cal-item-saved {
    background-color: #f7f7f7;
    color: #333333;
}
.cnt-cal-item-pending {
    background-color: #C56824;
    font-weight: normal;
}

.cnt-user-left-menu tr td {
    padding: 10px 0;
}
.cnt-user-left-menu tr td a {
    padding: 10px 0;
    display: block;
    text-decoration: none;
}
.cnt-user-left-menu tr td span {
    padding: 10px 0;
    display: block;
}
